import * as React from "react"
import { graphql } from "gatsby"
import Seo from "@atoms/seo"
import StoryGateway from "../components/organisms/story-gateway"

const HomePage = ({
  data: {
    ft: {
      home: { seo, stories, intro_loop },
    },
  },
}) => {
  return (
    <>
      {seo && <Seo {...seo} />}
      <StoryGateway stories={stories} intro={intro_loop} />
    </>
  )
}

export const query = graphql`
  query Home($locale: String) {
    ft {
      home(locale: $locale) {
        seo {
          ...Seo
        }
        intro_loop {
          ...IntroAnimation
        }
        stories(sort: "order") {
          ...StoryGateway
        }
      }
    }
  }
`

export default HomePage
