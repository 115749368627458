import { graphql, navigate } from "gatsby"
import React, { useRef, useState } from "react"
import tw, { css } from "twin.macro"
import useBreakpoints from "../../../utils/use-breakpoints"
import StoryGatewaySlide from "./slide"
import SlideWrapper from "./slide-wrapper"
import IntroAnimation from "../../molecules/intro/intro-animation"
import { motion } from "framer-motion"
import { useIntl } from "react-intl"

const StoryGateway = ({ stories, intro }) => {
  const [state, setState] = useState({
    active: stories[0]?.id,
  })
  const intl = useIntl()
  const activate = story => {
    if (state.active === story.id && story.released) {
      navigate(
        intl.locale === "en"
          ? `/story/${story.slug}`
          : `/${intl.locale}/${story.slug}`
      )
    } else {
      setState({ ...state, active: story.id })
    }
  }
  const closedWidth = useBreakpoints(
    [
      {
        query: "(min-width: 1280px)",
        value: 24,
      },
      {
        query: "(min-width: 640px)",
        value: 20,
      },
    ],
    15
  )
  const openWidth = w => 100 - w * (stories.length - 1)

  const activationRef = useRef(null)
  const onEnter = story => {
    resetActivationTimer()
    activationRef.current = {
      id: story.id,
      timer: setTimeout(() => setState({ ...state, active: story.id }), 200),
    }
  }
  const onLeave = story => {
    if (
      activationRef.current?.timer &&
      activationRef.current?.id === story.id
    ) {
      resetActivationTimer()
    }
  }

  const resetActivationTimer = () => {
    if (activationRef.current?.timer) {
      clearTimeout(activationRef.current.timer)
    }
  }

  return (
    <div tw="relative flex w-full">
      {stories.map((story, index) => (
        <motion.div
          key={story.id}
          css={[
            tw`relative overflow-hidden focus:outline-none`,
            css`
              width: ${openWidth(closedWidth)}vw;
            `,
          ]}
          variants={{
            active: {
              width: `${openWidth(closedWidth)}vw`,
            },
            passive: {
              width: `${closedWidth}vw`,
            },
          }}
          initial={state.active === story.id ? "active" : "passive"}
          animate={state.active === story.id ? "active" : "passive"}
        >
          <div
            css={[
              css`
                width: ${openWidth(closedWidth)}vw;
              `,
            ]}
          >
            <SlideWrapper>
              <StoryGatewaySlide
                story={story}
                isActive={state.active === story.id}
                closedWidth={closedWidth}
                openWidth={openWidth(closedWidth)}
              >
                {intro && index === 0 && <IntroAnimation data={intro} />}
              </StoryGatewaySlide>
            </SlideWrapper>
          </div>
          <button
            tw="absolute block w-full h-full z-50 inset-0 focus:outline-none"
            style={{
              cursor:
                state.active === story.id && !story.released
                  ? "default"
                  : "pointer",
            }}
            onClick={() => {
              resetActivationTimer()
              activate(story)
            }}
            onMouseEnter={() => onEnter(story)}
            onMouseLeave={() => onLeave(story)}
          ></button>
        </motion.div>
      ))}
    </div>
  )
}

export const query = graphql`
  fragment StoryGateway on FT_Story {
    order
    released
    story_preview {
      vimeo_url
      vimeo_data {
        files {
          quality
          size
          link
          type
          width
          height
        }
      }
      trailer {
        mime
        localFile {
          publicURL
        }
        url
      }
      poster {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 1.77777778
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              transformOptions: { cropFocus: ENTROPY }
            )
          }
        }
        url
      }
      subtitle
    }
    id
    name
    character_name
    slug
    location
    icon
  }
`

export default StoryGateway
