import React from "react"
import tw, { css } from "twin.macro"
import { motion } from "framer-motion"
import HeroLoop from "./intro-loop"
import AppIcon from "../../atoms/icon"
import { useMediaQuery } from "../../../utils/use-media-query"
import { graphql } from "gatsby"

const MQ = "(min-aspect-ratio: 7/3), (max-height: 400px), (max-width: 1023px)"

const IntroAnimation = ({ data }) => {
  const matches = useMediaQuery(MQ)
  const outDuration = { transition: { duration: 0 } }
  const rulerVariants = matches
    ? {
        out: { width: 0, height: 0, ...outDuration },
        in: { width: "100%", height: 0 },
        novideo: { width: "100%", height: 0 },
      }
    : {
        out: { height: 0, width: 0, ...outDuration },
        in: { height: "100%", width: 0 },
        novideo: { height: "100%", width: 0 },
      }
  const titleVariants = matches
    ? {
        out: { y: "300%", x: 0, ...outDuration },
        in: { y: 0, x: 0 },
        novideo: { y: 0, x: 0 },
      }
    : {
        out: { x: "-120%", y: 0, ...outDuration },
        in: { x: 0, y: 0 },
        novideo: { x: 0, y: 0 },
      }
  return (
    <motion.div
      css={[
        tw`relative z-10 w-11/12`,
        css`
          max-width: 236px;
          @media (min-width: 580px) {
            max-width: 360px;
          }
          @media (min-width: 1650px) {
            max-width: 420px;
          }
        `,
      ]}
      variants={{
        out: {
          y: 100,
          opacity: 0,
          transition: { when: "afterChildren", duration: 0.4 },
        },
        in: { y: 0, opacity: 1, transition: { when: "beforeChildren" } },
      }}
    >
      <HeroLoop caption={data.caption} isShallow={matches} />
      <div
        css={[
          css`
            padding-bottom: 100%;
          `,
          tw`relative w-full`,
        ]}
      >
        <div tw="absolute inset-0 flex items-center justify-center lg:-mr-2">
          <div
            css={[
              tw`flex items-center text-brand-blue`,
              css`
                @media ${MQ} {
                  ${tw`flex-col text-center`}
                }
              `,
            ]}
          >
            {data.icon && (
              <motion.div
                variants={{
                  in: { transition: { delayChildren: 1 } },
                  out: { transition: { duration: 0 } },
                }}
                css={[
                  tw`w-16 flex-shrink-0 flex-grow-0 xxs:-mt-2 md:(w-28) lg:(w-24 mt-0) xxl:(w-28 pl-0)`,
                ]}
              >
                <AppIcon tw="block w-full" name={data.icon} useInView={false} />
              </motion.div>
            )}
            <motion.div
              variants={{
                in: { transition: { staggerChildren: 0.5 } },
                out: { transition: { duration: 0 } },
              }}
              tw="relative px-2 overflow-hidden"
            >
              {!matches && (
                <motion.span
                  variants={rulerVariants}
                  css={[
                    tw`block absolute bottom-0 h-full border-l -ml-2 border-brand-green`,
                  ]}
                ></motion.span>
              )}
              {data.title && (
                <motion.h1
                  variants={titleVariants}
                  tw="block text-4xl leading-none font-black font-display text-white uppercase xxs:text-5xl md:text-7xl lg:text-6xl"
                >
                  {data.title}
                </motion.h1>
              )}
              {matches && (
                <motion.span
                  variants={rulerVariants}
                  css={[
                    tw`block w-full border-l -ml-2 border-brand-green`,
                    css`
                      @media ${MQ} {
                        ${tw`border-l-0 border-t h-0 w-full bottom-auto right-0 mt-0 mb-2`}
                        transform: scaleX(1.2);
                      }
                    `,
                  ]}
                ></motion.span>
              )}
              {data.subtitle && (
                <motion.h2
                  variants={titleVariants}
                  css={[
                    tw`text-brand-green uppercase text-lg font-bold leading-none block w-36
                        xxs:(text-xl leading-none w-40)
                        md:(text-3xl w-72 leading-none)
                        lg:(text-2xl leading-none)`,
                    css`
                      @media ${MQ} {
                        ${tw`mx-auto`}
                      }
                    `,
                  ]}
                >
                  {data.subtitle}
                </motion.h2>
              )}
            </motion.div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export const query = graphql`
  fragment IntroAnimation on FT_ComponentMoleculesIntroLoop {
    title
    subtitle
    icon
    caption
  }
`

export default IntroAnimation
