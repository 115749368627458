/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useIntl } from "react-intl"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ meta_description, meta_title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const intl = useIntl()

  const metaDescription = meta_description || site.siteMetadata.description
  const lang = intl.locale || "en"

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={meta_title || site.siteMetadata?.title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: meta_title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: meta_title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    />
  )
}

Seo.propTypes = {
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export const fragment = graphql`
  fragment Seo on FT_ComponentCommonSeo {
    meta_title
    meta_description
    og_image {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`

export default Seo
