import "twin.macro"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect, useRef, useState } from "react"

const selectVimeoFile = (videoEl, files, width, height) => {
  const hls = files.find(({ quality }) => quality === "hls")
  if (hls && videoEl.canPlayType(hls.type) === "probably") {
    return hls
  }
  let file
  files.forEach(x => {
    if (
      width &&
      height &&
      x.width >= width &&
      x.height >= height &&
      (!file || file.size > x.size)
    ) {
      file = x
    } else if (x.quality === "hd" && (!file || file.size > x.size)) {
      file = x
    }
  })
  return file
}

const useVideoSource = (localVideo, vimeoVideo, width, height) => {
  const [source, setSource] = useState(null)
  useEffect(() => {
    const video = document.createElement("video")
    if (vimeoVideo) {
      const vimeoFile = selectVimeoFile(video, vimeoVideo.files, width, height)
      if (vimeoFile && vimeoFile.link) {
        setSource({
          type: vimeoFile.type,
          src: vimeoFile.link,
        })
        return
      }
    }
    if (
      localVideo &&
      localVideo.localFile?.publicURL &&
      localVideo.mime &&
      video.canPlayType(localVideo.mime)
    ) {
      setSource({
        type: localVideo.mime,
        src: localVideo.localFile.publicURL,
      })
    }
  }, [localVideo, vimeoVideo, width, height])
  return source
}

const BackgroundVideo = ({
  localVideo,
  fallbackImage,
  vimeoVideo,
  width,
  height,
}) => {
  const fallback = fallbackImage ? getImage(fallbackImage.localFile) : null
  const [showFallback, setShowFallback] = useState(false)
  const videoRef = useRef()
  const source = useVideoSource(localVideo, vimeoVideo, width, height)

  useEffect(() => {
    if (!videoRef.current) return
    try {
      const current = videoRef.current
      const prom = current.play()
      if (typeof prom !== "undefined") {
        const ttl = setTimeout(() => {
          setShowFallback(true)
        }, 3000)
        const onPlay = () => {
          clearTimeout(ttl)
          current.removeEventListener("play", onPlay)
        }
        current.addEventListener("play", onPlay)
        prom.catch(err => {
          setShowFallback(true)
        })
        return () => {
          current.removeEventListener("play", onPlay)
        }
      }
    } catch (err) {
      setShowFallback(true)
    }
  }, [videoRef, source])

  if ((!fallback || !showFallback) && source) {
    return (
      <video
        ref={videoRef}
        aria-hidden={true}
        controls={false}
        muted={true}
        playsInline={true}
        disablePictureInPicture={true}
        disableRemotePlayback={true}
        loop={true}
        tw="absolute inset-0 object-cover w-full h-full"
      >
        <source src={source.src} type={source.type} />
      </video>
    )
  }
  if (showFallback) {
    return (
      <GatsbyImage
        tw="inset-0 object-cover w-full h-full !absolute"
        image={fallback}
        alt=""
        aria-hidden="true"
      />
    )
  }
  return ""
}

export default BackgroundVideo
